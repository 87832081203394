import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { ButtonFL } from '../../components/ButtonFL';

import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Excluir, Get, Patch, Post } from '../../data/Verbs';
import { Loader } from '../../components/Loader';
import { Alert } from '../../components/common/Alert';
import { useError } from '../../contexts/ErrorContext';
import { Oportunidade } from './Oportunidade';
import { OportunidadesList } from './OportunidadesList';
import { OportunidadesSearch } from './OportunidadesSearch';

export function Oportunidades() {
    const [query, setQuery] = useState(localStorage.getItem('oportunidades-query') || '');
    const [ordenacao, setOrdenacao] = useState('nome_asc');
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(mensagemVazio);
    const [oportunidades, setOportunidades] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [abreOportunidade, setAbreOportunidade] = useState({
        status: false,
        oportunidade: {
            id: 0,
        },
    });

    const { showError } = useError();

    const salvar = async (oportunidade) => {
        try {
            setIsLoading(true);

            const url = `${process.env.REACT_APP_API_URL}/oportunidades`;

            let message = 'Oportunidade inserida com sucesso';

            if (Number(oportunidade.id) === 0) {
                delete oportunidade.id;

                await Post(url, oportunidade);
            } else {
                await Patch(`${url}/${oportunidade.id}`, oportunidade);
                message = 'Oportunidade alterada com sucesso';
            }

            closeOportunidade();
            showMessage(
                {
                    variant: 'success',
                    message,
                },
                setMessage,
            );
        } catch (error) {
            closeOportunidade();
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const ExcluirOportunidade = async (oportunidade) => {
        setIsLoading(true);

        try {
            await Excluir(`${process.env.REACT_APP_API_URL}/oportunidades/${oportunidade.id}`);

            closeOportunidade();
            showMessage(
                {
                    variant: 'warning',
                    message: 'Oportunidade excluida com sucesso',
                },
                setMessage,
            );
        } catch (error) {
            closeOportunidade();
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBusca = (_query) => {
        setIsLoading(true);

        setQuery(_query);
    };

    const openOportunidade = (_oportunidade) => {
        setIsLoading(true);

        setAbreOportunidade({
            status: true,
            oportunidade: _oportunidade,
        });
    };

    const closeOportunidade = () => {
        setIsLoading(true);

        setAbreOportunidade({
            status: false,
            oportunidade: {
                id: '0',
            },
        });
    };

    const handleOrdenar = (campo) => {
        setIsLoading(true);

        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await Get(
                    `${process.env.REACT_APP_API_URL}/oportunidades?query=${query}&sort=${ordenacao}`,
                );

                setOportunidades(data);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, [query, ordenacao, abreOportunidade]);

    const fetchFormData = async () => {
        try {
            const clientesData = await Get(`${process.env.REACT_APP_API_URL}/clientes?sort=nome_asc`);
            setClientes(clientesData);
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        fetchFormData();
    }, []);

    useEffect(() => {
        localStorage.setItem('oportunidades-query', query);
    }, [query]);

    return (
        <>
            <div className={styles.container}>
                {message.message && <Alert variant={message.variant} message={message.message} />}
            </div>

            {isLoading && <Loader />}
            {abreOportunidade.status ? (
                <Oportunidade
                    clientes={clientes}
                    oportunidade={abreOportunidade.oportunidade}
                    handleSalvar={salvar}
                    handleFechar={closeOportunidade}
                />
            ) : (
                <div className={styles.container}>
                    <OportunidadesSearch
                        query={query}
                        handleAdicionar={
                            <ButtonFL
                                onClick={() =>
                                    openOportunidade({
                                        id: '0',
                                    })
                                }
                                oportunidade={abreOportunidade.oportunidade}
                                variant="contained"
                                startIcon={<AddCircle />}
                                sx={{ mt: 1, mb: 1 }}
                                textKey="Adicionar"
                            />
                        }
                        handleBusca={handleBusca}
                    />

                    <Grid
                        component="div"
                        sx={{
                            backgroundColor: 'white',
                            p: 2,
                            textAlign: 'center',
                            width: '100%',
                            my: 2,
                            borderRadius: '8px',
                        }}
                    >
                        {query == ''
                            ? 'Mostrando todos os registros'
                            : oportunidades.length == 0
                            ? 'Não foram encontrados registros'
                            : `Listando ${oportunidades.length} registros para a sua busca: ${query}`}
                    </Grid>

                    <OportunidadesList
                        handleEditar={openOportunidade}
                        handleExcluir={ExcluirOportunidade}
                        handleOrdenar={handleOrdenar}
                        oportunidades={oportunidades}
                        ordenacao={ordenacao}
                        clientes={clientes}
                    />
                </div>
            )}
        </>
    );
}
