import { Lightbulb } from '@mui/icons-material';
import { Avatar, Box, FormControl, InputLabel, OutlinedInput, Typography, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import { ButtonFL } from '../../components/ButtonFL';
import { EXCLUSIVO_OPTIONS } from '../../data/Interfaces';

export const Aprenda = ({ aprenda, clientes, handleFechar, handleSalvar }) => {
    const [form, setForm] = useState({
        cliente: aprenda.cliente || '',
        titulo: aprenda.titulo || '',
        ordem: aprenda.ordem || '',
        feed: aprenda.feed || '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updated = {
            ...aprenda,
            cliente: form.cliente,
            titulo: form.titulo,
            ordem: form.ordem,
            feed: form.feed,
        };

        handleSalvar(updated);
    };

    return (
        <Box
            sx={{
                margin: 2,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--white-color)',
            }}
        >
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <Lightbulb />
            </Avatar>

            <Typography component="h1" variant="h5">
                {aprenda.id ? 'Adicionar Aprenda' : 'Alterar Aprenda'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="titulopo">Título</InputLabel>
                    <OutlinedInput
                        name="titulo"
                        id="titulo"
                        value={form.titulo}
                        onChange={handleInputChange}
                        label="Título"
                    />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel id="label-cliente">Cliente</InputLabel>
                    <Select
                        labelId="label-cliente"
                        name="cliente"
                        id="cliente"
                        value={form.cliente || ''}
                        onChange={handleInputChange}
                        label="Cliente"
                    >
                        <MenuItem value="" disabled>
                            Selecione um cliente
                        </MenuItem>
                        {clientes?.length > 0 &&
                            clientes.map((cliente) => (
                                <MenuItem key={cliente?.id} value={cliente?.id}>
                                    {cliente?.nome}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel>Ordem (número)</InputLabel>
                    <OutlinedInput
                        name="ordem"
                        id="ordem"
                        value={form.ordem}
                        onChange={handleInputChange}
                        label="Ordem"
                    />
                </FormControl>

                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="titulopo">Feed (id)</InputLabel>
                    <OutlinedInput
                        name="feed"
                        id="feed"
                        value={form.feed}
                        onChange={handleInputChange}
                        label="Feed ID"
                    />
                </FormControl>

                <ButtonFL
                    type="submit"
                    fullWidth
                    textKey="Salvar"
                    sx={{ mt: 3, mb: 2, backgroundColor: 'var(--primary-color)' }}
                />

                <ButtonFL fullWidth textKey="Voltar" onClick={() => handleFechar(false)} />
            </Box>
        </Box>
    );
};
