export const mensagemVazio = {
    variant: '',
    message: '',
};

export const showMessage = ({ variant, message }, setMessage) => {
    setMessage({ variant, message });

    setTimeout(() => {
        setMessage(mensagemVazio);
    }, 3000);
};

export const accessLevels = [
    { value: 1, label: 'Administrador' },
    { value: 2, label: 'Operador' },
    { value: 3, label: 'Cliente' },
];

export const escopoVazio = {
    titulo: '',
    data: [],
};

export const IcampanhasItensStatus = {
    WAITING: 'WAITING_FOR_APPROVAL',
    WAITING_CLIENT: 'WAITING_FOR_CLIENTE_APPROVAL',
    REPROVED: 'REPROVED',
    REPROVED_CLIENT: 'REPROVED_CLIENT',
    APPROVED: 'APPROVED',
    APPROVED_CLIENT: 'APPROVED_CLIENT',
    COMPLETED: 'COMPLETED',
    CANCELED: 'CANCELED',
};

export const TYPE = {
    COMMUNITY: 'COMMUNITY',
    CAMPAIGN: 'CAMPAIGN',
};

export const VISIBILITY = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
};

export const EXCLUSIVO_OPTIONS = [
    { value: 'EXCLUSIVO', title: 'Exclusivo' },
    { value: 'EXCLUSIVO_E_ABERTO', title: 'Exclusivo e Aberto' },
    { value: 'SO_ABERTO', title: 'Só Aberto' },
];

export const TIPOS_OPTIONS = [
    { value: 'VIDEO', title: 'Vídeo' },
    { value: 'FOTO', title: 'Foto' },
    { value: 'LINK', title: 'Link' },
];
