import { ArrowDownward, ArrowUpward, Delete, ModeEdit, People } from '@mui/icons-material';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OportunidadesList = ({ oportunidades, handleEditar, handleExcluir, handleOrdenar, ordenacao }) => {
    const editar = (oportunidade) => {
        handleEditar(oportunidade);
    };

    const excluir = (oportunidade) => {
        handleExcluir(oportunidade);
    };

    const isOrdenadoAsc = (campo) => {
        return ordenacao === `${campo}_asc`;
    };

    const isOrdenadoDesc = (campo) => {
        return ordenacao === `${campo}_desc`;
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#424242',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const comunidades = null;

    const iconStyle = {
        fontSize: '14px',
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            onClick={() => handleOrdenar('titulo')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('titulo') || isOrdenadoDesc('titulo') ? 'bold' : 'normal',
                            }}
                        >
                            Título {isOrdenadoAsc('titulo') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('titulo') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell>Comunidade</StyledTableCell>
                        <StyledTableCell>Tipo</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Inscrição (De/Até)</StyledTableCell>
                        <StyledTableCell>Recompensa</StyledTableCell>
                        <StyledTableCell>Vagas</StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {oportunidades.map((oportunidade) => (
                        <TableRow
                            key={oportunidade.id}
                            className="nowrap"
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {oportunidade.titulo}
                            </TableCell>
                            <TableCell>
                                {comunidades?.find((c) => c.id === oportunidade.comunidade)?.nome || 'Desconhecida'}
                            </TableCell>
                            <TableCell>{oportunidade.tipo}</TableCell>
                            <TableCell>{oportunidade.status}</TableCell>
                            <TableCell>
                                {oportunidade.periodo_inscricao_de
                                    ? new Date(oportunidade.periodo_inscricao_de).toLocaleDateString()
                                    : 'N/A'}{' '}
                                -{' '}
                                {oportunidade.periodo_inscricao_ate
                                    ? new Date(oportunidade.periodo_inscricao_ate).toLocaleDateString()
                                    : 'N/A'}
                            </TableCell>
                            <TableCell>
                                {oportunidade.recompensa_tipo
                                    ? `${oportunidade.recompensa_tipo} - ${oportunidade.recompensa_valor || 0}`
                                    : 'N/A'}
                            </TableCell>
                            <TableCell>{oportunidade.vagas || 'N/A'}</TableCell>
                            <TableCell align="right">
                                <Button
                                    aria-label="Editar Oportunidade"
                                    size="small"
                                    onClick={() => editar(oportunidade)}
                                    sx={{ color: 'var(--primary-color)' }}
                                >
                                    <ModeEdit />
                                </Button>

                                <Button
                                    aria-label="Excluir Oportunidade"
                                    size="small"
                                    onClick={() => excluir(oportunidade)}
                                    sx={{ color: 'var(--primary-color)' }}
                                >
                                    <Delete fontSize="small" />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
