import { Get, Post, Patch, Excluir } from '../data/Verbs';

export const apiGetRankingPeriodos = async (campanha, active, dateStart, dateEnd) => {
    const params = new URLSearchParams();
    if (campanha) params.append('campanha', campanha);
    if (active !== undefined) params.append('active', active);
    if (dateStart) params.append('dateStart', dateStart);
    if (dateEnd) params.append('dateEnd', dateEnd);

    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/rankingPeriodos?${params.toString()}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiPostRankingPeriodo = async (data) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/rankingPeriodos`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiDeleteRankingPeriodo = async (id) => {
    try {
        const response = await Excluir(`${process.env.REACT_APP_API_URL}/rankingPeriodos/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};
