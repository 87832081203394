import React, { useState, useEffect } from 'react';
import { TextField, Chip, Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export function TagsInput({ initialTags = [], onTagsChange }) {
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState(initialTags);

    useEffect(() => {
        setTags(initialTags);
    }, [initialTags]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            event.preventDefault();
            if (!tags.includes(inputValue)) {
                const newTags = [...tags, inputValue];
                setTags(newTags);
                onTagsChange(newTags);
            }
            setInputValue('');
        }
    };

    const handleDelete = (tagToDelete) => {
        const newTags = tags.filter((tag) => tag !== tagToDelete);
        setTags(newTags);
        onTagsChange(newTags);
    };

    return (
        <Box>
            <TextField
                variant="outlined"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                fullWidth
            />
            <Box my={2} display="flex" flexWrap="wrap" gap={1}>
                {tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleDelete(tag)}
                        deleteIcon={
                            <IconButton size="small">
                                <Close fontSize="small" />
                            </IconButton>
                        }
                        color="primary"
                        variant="outlined"
                    />
                ))}
            </Box>
        </Box>
    );
}
