import { Business } from '@mui/icons-material';
import {
    Avatar,
    Box,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { useState } from 'react';
import { ButtonFL } from '../../components/ButtonFL';
import { EXCLUSIVO_OPTIONS } from '../../data/Interfaces';

export const Cliente = ({ cliente, handleFechar, handleSalvar }) => {
    const [form, setForm] = useState({
        nome: cliente.nome || '',
        descricao: cliente.descricao || '',
        aprenda: cliente.aprenda || '',
        oportunidade: cliente.oportunidade || '',
        environment: cliente.environment || '',
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        const updated = {
            ...cliente,
            nome: form.nome,
            descricao: form.descricao,
            aprenda: form.aprenda,
            oportunidade: form.oportunidade,
            environment: form.environment,
        };

        handleSalvar(updated);
    };

    const handleToggleChange = (name) => (event, newValue) => {
        if (newValue !== null) {
            setForm({
                ...form,
                [name]: newValue,
            });
        }
    };

    return (
        <Box
            sx={{
                margin: 2,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--white-color)',
            }}
        >
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <Business />
            </Avatar>

            <Typography component="h1" variant="h5">
                {cliente.id ? 'Adicionar Cliente' : 'Alterar Cliente'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="nome"
                    label="Nome do Cliente"
                    id="nome"
                    value={form.nome}
                    onChange={(e) => setForm({ ...form, nome: e.target.value })}
                    autoComplete="Nome do Cliente"
                />

                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="descricao">Descrição</InputLabel>
                    <OutlinedInput
                        multiline
                        minRows={3}
                        name="descricao"
                        id="descricao"
                        value={form.descricao}
                        onChange={(e) => setForm({ ...form, descricao: e.target.value })}
                        label="Descrição"
                    />
                </FormControl>

                {/* Campo Aprenda */}
                <Typography variant="subtitle1">Aprenda</Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={form.aprenda}
                    exclusive
                    onChange={handleToggleChange('aprenda')}
                    fullWidth
                    sx={{ mb: 2 }}
                >
                    {EXCLUSIVO_OPTIONS.map((option) => (
                        <ToggleButton key={option.value} value={option.value}>
                            {option.title}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                <Typography variant="subtitle1">Oportunidades</Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={form.oportunidade}
                    exclusive
                    onChange={handleToggleChange('oportunidade')}
                    fullWidth
                    sx={{ mb: 2 }}
                >
                    {EXCLUSIVO_OPTIONS.map((option) => (
                        <ToggleButton key={option.value} value={option.value}>
                            {option.title}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                <TextField
                    margin="normal"
                    fullWidth
                    name="environment"
                    label="Environment"
                    id="environment"
                    value={form.environment}
                    onChange={(e) => setForm({ ...form, environment: e.target.value })}
                    autoComplete="Environment"
                />

                <ButtonFL
                    type="submit"
                    fullWidth
                    textKey="Salvar"
                    sx={{ mt: 3, mb: 2, backgroundColor: 'var(--primary-color)' }}
                />

                <ButtonFL fullWidth textKey="Voltar" onClick={() => handleFechar(false)} />
            </Box>
        </Box>
    );
};
