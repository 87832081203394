import { RecordVoiceOverOutlined } from '@mui/icons-material';
import {
    Avatar,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    Chip,
    Grid,
    Collapse,
    IconButton,
    Container,
    Switch,
    FormControlLabel,
} from '@mui/material';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ButtonFL } from '../../components/ButtonFL';
import {
    defaultValuesForms,
    states,
    instagramFollowersRanges,
    tiktokFollowersRanges,
    youtubeFollowersRanges,
    confirmationOptions,
    genderOptions,
} from '../../utils';

import { apiGetGames, apiGetGenderGames, apiGetCategories } from '../../services';
import { useError } from '../../contexts/ErrorContext';
import { Get } from '../../data/Verbs';

export const Creator = ({ creator, handleFechar, handleSalvar }) => {
    const defaultValues = defaultValuesForms(creator);
    const [form, setForm] = useState(defaultValues);

    const [showAdminInfo, setShowAdminInfo] = useState(true);
    const [showBasicInfo, setShowBasicInfo] = useState(true);
    const [showContentInfo, setShowContentInfo] = useState(true);
    const [showGameInfo, setShowGameInfo] = useState(true);
    const [showProfileInfo, setShowProfileInfo] = useState(true);
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(true);
    const [gamesList, setGamesList] = useState([]);
    const [gamesGender, setGamesGender] = useState([]);
    const [categories, setCategories] = useState([]);
    const [clientes, setClientes] = useState([]);

    const { showError } = useError();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const arrayToString = (value) => (Array.isArray(value) ? value.join(', ') : value);

        const {
            admin_clientes,
            instagram_seguidores,
            tiktok_seguidores,
            youtube_seguidores,
            gamer_jogos_genero,
            gamer_jogos,
            gamer,
            ...rest
        } = form;

        const formToSubmit = {
            ...rest,
            admin_clientes: arrayToString(admin_clientes),
            instagram_seguidores: arrayToString(instagram_seguidores),
            tiktok_seguidores: arrayToString(tiktok_seguidores),
            youtube_seguidores: arrayToString(youtube_seguidores),
            gamer_jogos_genero: arrayToString(gamer_jogos_genero),
            gamer_jogos: arrayToString(gamer_jogos),
        };

        await handleSalvar(formToSubmit, creator.id);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: typeof value === 'string' ? value.split(',') : value,
        });
    };

    const toggleSection = (section) => {
        switch (section) {
            case 'basicInfo':
                setShowBasicInfo(!showBasicInfo);
                break;
            case 'adminInfo':
                setShowAdminInfo(!showAdminInfo);
                break;
            case 'contentInfo':
                setShowContentInfo(!showContentInfo);
                break;
            case 'gameInfo':
                setShowGameInfo(!showGameInfo);
                break;
            case 'profileInfo':
                setShowProfileInfo(!showProfileInfo);
                break;
            case 'additionalInfo':
                setShowAdditionalInfo(!showAdditionalInfo);
                break;
            default:
                break;
        }
    };

    const fetchFormData = async () => {
        try {
            const clientesData = await Get(`${process.env.REACT_APP_API_URL}/clientes?sort=nome_asc`);
            setClientes(clientesData);

            const [games, gamesGender, categories] = await Promise.all([
                apiGetGames(),
                apiGetGenderGames(),
                apiGetCategories(),
            ]);

            setGamesList(games);
            setGamesGender(gamesGender);
            setCategories(categories);
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        fetchFormData();
    }, []);

    return (
        <Container
            maxWidth="xl"
            disableGutters
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--white-color)',
                p: { xs: 2, md: 6 },
                m: 1,
                color: 'var(--grey-900-color)',
            }}
        >
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <RecordVoiceOverOutlined />
            </Avatar>

            <Typography component="h1" variant="h5">
                {creator.id === '0' ? 'Adicionar Creator' : 'Alterar Creator'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
                    <Typography variant="h6">Informações de Administrador</Typography>
                    <IconButton onClick={() => toggleSection('adminInfo')}>
                        {showAdminInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={showAdminInfo}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form.admin_privilegios || false}
                                        onChange={(event) =>
                                            setForm({ ...form, admin_privilegios: event.target.checked })
                                        }
                                        name="admin_privilegios"
                                    />
                                }
                                label="Creator é Administrador?"
                            />

                            <FormControl fullWidth margin="normal">
                                <InputLabel id="admin-clientes-label">Clientes Administrados</InputLabel>
                                <Select
                                    labelId="admin-clientes-label"
                                    id="admin_clientes"
                                    name="admin_clientes"
                                    multiple
                                    value={form.admin_clientes?.split(',') || []}
                                    onChange={(event) => {
                                        const selectedValues = event.target.value;
                                        setForm({
                                            ...form,
                                            admin_clientes: selectedValues.join(','),
                                        });
                                    }}
                                    input={<OutlinedInput label="Clientes Administrados" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((id) => {
                                                const cliente = clientes.find((c) => String(c.id) === id);
                                                return <Chip key={id} label={cliente?.nome || id} />;
                                            })}
                                        </Box>
                                    )}
                                >
                                    {clientes.map((cliente) => (
                                        <MenuItem key={cliente.id} value={String(cliente.id)}>
                                            {cliente.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
                    <Typography variant="h6">Informações básicas de cadastro</Typography>

                    <IconButton onClick={() => toggleSection('basicInfo')}>
                        {showBasicInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>

                <Collapse in={showBasicInfo}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel id="label-cliente">Cliente</InputLabel>
                                <Select
                                    labelId="label-cliente"
                                    name="cliente"
                                    id="cliente"
                                    value={form.cliente || ''}
                                    onChange={handleInputChange}
                                    label="Cliente"
                                >
                                    <MenuItem value="" disabled>
                                        Selecione um cliente
                                    </MenuItem>
                                    {clientes?.length > 0 &&
                                        clientes.map((cliente) => (
                                            <MenuItem key={cliente?.id} value={cliente?.id}>
                                                {cliente?.nome}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="nome"
                                label="Nome Completo"
                                id="nome"
                                value={form.nome}
                                onChange={handleInputChange}
                                autoComplete="nome"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="apelido"
                                label="Como você quer que te chame?"
                                id="apelido"
                                value={form.apelido}
                                onChange={handleInputChange}
                                autoComplete="comoTeChamar"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="genero-label">Gênero</InputLabel>
                                <Select
                                    labelId="genero-label"
                                    id="genero"
                                    name="genero"
                                    value={form.genero}
                                    onChange={handleInputChange}
                                    label="Gênero"
                                >
                                    {genderOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                type="email"
                                required
                                fullWidth
                                name="email"
                                label="E-mail"
                                id="email"
                                value={form.email}
                                onChange={handleInputChange}
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputMask
                                mask="(99) 99999-9999"
                                required
                                fullWidth
                                value={form.telefone}
                                onChange={handleInputChange}
                            >
                                {(inputProps) => (
                                    <TextField {...inputProps} margin="normal" name="telefone" label="Telefone" />
                                )}
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="datanascimento"
                                label="Data de Nascimento"
                                type="date"
                                id="datanascimento"
                                value={form.datanascimento}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="cidade"
                                label="Você mora em qual cidade?"
                                id="cidade"
                                value={form.cidade}
                                onChange={handleInputChange}
                                autoComplete="cidade"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="uf-label">UF do Estado</InputLabel>
                                <Select
                                    labelId="uf-label"
                                    id="estado"
                                    label="UF do Estado"
                                    value={form.estado}
                                    onChange={handleInputChange}
                                    name="estado"
                                >
                                    {states?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
                    <Typography variant="h6">Informações de conteúdo</Typography>
                    <IconButton onClick={() => toggleSection('contentInfo')}>
                        {showContentInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={showContentInfo}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="categoria-label">Categoria</InputLabel>
                                <Select
                                    labelId="categoria-label"
                                    id="categorias"
                                    name="categorias"
                                    multiple
                                    value={form.categorias}
                                    onChange={handleSelectChange}
                                    input={<OutlinedInput label="Categorias" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected?.map((value) => {
                                                const category = categories.find((option) => option.id === value);
                                                return <Chip key={value} label={category?.nome} />;
                                            })}
                                        </Box>
                                    )}
                                >
                                    {categories?.map((option) => (
                                        <MenuItem key={option?.id} value={option?.id}>
                                            {option?.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="instagram"
                                label="Perfil Instagram"
                                id="instagram"
                                value={form.instagram}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="seguidores-instagram-label">
                                    Instagram - Quantidade de seguidores
                                </InputLabel>
                                <Select
                                    labelId="seguidores-instagram-label"
                                    id="instagram_seguidores"
                                    name="instagram_seguidores"
                                    value={form.instagram_seguidores}
                                    onChange={handleSelectChange}
                                    label="Instagram - Quantidade de seguidores"
                                >
                                    {instagramFollowersRanges?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="tiktok"
                                label="Perfil TikTok"
                                id="tiktok"
                                value={form.tiktok}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="seguidores-tiktok-label">TikTok - Quantidade de seguidores</InputLabel>

                                <Select
                                    labelId="seguidores-tiktok-label"
                                    id="tiktok_seguidores"
                                    name="tiktok_seguidores"
                                    value={form.tiktok_seguidores}
                                    onChange={handleSelectChange}
                                >
                                    {tiktokFollowersRanges?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="youtube"
                                label="Perfil Youtube"
                                id="youtube"
                                value={form.youtube}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="seguidores-youtube-label">
                                    Youtube - Quantidade de seguidores
                                </InputLabel>

                                <Select
                                    labelId="seguidores-youtube-label"
                                    id="youtube_seguidores"
                                    name="youtube_seguidores"
                                    value={form.youtube_seguidores}
                                    onChange={handleSelectChange}
                                >
                                    {youtubeFollowersRanges?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="youtube-shorts-label">Você usa o Youtube shorts?</InputLabel>
                                <Select
                                    labelId="youtube-shorts-label"
                                    id="youtubeShorts"
                                    label="Você usa o Youtube shorts?"
                                    value={form.youtubeShorts}
                                    onChange={handleInputChange}
                                    name="youtubeShorts"
                                >
                                    {confirmationOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="discordId"
                                label="ID do discord"
                                id="discordId"
                                value={form.discordId}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Collapse>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
                    <Typography variant="h6">Informações sobre jogos</Typography>
                    <IconButton onClick={() => toggleSection('gameInfo')}>
                        {showGameInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={showGameInfo}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="create-game-label">Você cria conteúdo de algum jogo?</InputLabel>
                                <Select
                                    labelId="create-game-label"
                                    id="gamer"
                                    label="Você cria conteúdo de algum jogo?"
                                    value={form.gamer}
                                    onChange={handleInputChange}
                                    name="gamer"
                                >
                                    {confirmationOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="jogos-label">Quais jogos?</InputLabel>
                                <Select
                                    labelId="jogos-label"
                                    id="gamer_jogos"
                                    name="gamer_jogos"
                                    multiple
                                    value={form.gamer_jogos}
                                    onChange={handleSelectChange}
                                    input={<OutlinedInput label="Quais jogos?" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected?.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {gamesList?.map((option) => (
                                        <MenuItem key={option.id} value={option.nome}>
                                            {option.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="gamer_jogos_outros"
                                label="Outros"
                                id="gamer_jogos_outros"
                                value={form.outrosJogos}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="genero-jogos-label">Qual seu gênero de jogo preferido?</InputLabel>
                                <Select
                                    labelId="genero-jogos-label"
                                    id="gamer_jogos_genero"
                                    name="gamer_jogos_genero"
                                    multiple
                                    value={form.gamer_jogos_genero}
                                    onChange={handleSelectChange}
                                    input={<OutlinedInput label="Qual seu gênero de jogo preferido?" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected?.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {gamesGender?.map((option) => (
                                        <MenuItem key={option.id} value={option.nome}>
                                            {option.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
                    <Typography variant="h6">Informações do perfil (Plataforma)</Typography>
                    <IconButton onClick={() => toggleSection('profileInfo')}>
                        {showProfileInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={showProfileInfo}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="login"
                                label="Login"
                                id="login"
                                value={form.login}
                                onChange={handleInputChange}
                                autoComplete="login"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="senha"
                                label="Senha"
                                id="senha"
                                type="password"
                                value={form.senha}
                                onChange={handleInputChange}
                                autoComplete="senha"
                            />
                        </Grid>
                    </Grid>
                </Collapse>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
                    <Typography variant="h6">Complemente seu perfil</Typography>
                    <IconButton onClick={() => toggleSection('additionalInfo')}>
                        {showAdditionalInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={showAdditionalInfo}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="infos_marcas"
                                label="Tem alguma marca queridinha e que adoraria fazer campanha?"
                                id="infos_marcas"
                                value={form.infos_marcas}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="infos_restricoesalimentares"
                                label="Se rolar uma campanha de comida, tem alguma coisa que você não pode comer?"
                                id="infos_restricoesalimentares"
                                value={form.infos_restricoesalimentares}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="infos_marcasfavoritas"
                                label="Quais marcas são suas favoritas no dia a dia?"
                                id="infos_marcasfavoritas"
                                value={form.infos_marcasfavoritas}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="infos_pet-label">Tem um bichinho de estimação fofo por aí?</InputLabel>
                                <Select
                                    labelId="infos_pet-label"
                                    id="infos_pet"
                                    label="Tem um bichinho de estimação fofo por aí?"
                                    value={form.infos_pet}
                                    onChange={handleInputChange}
                                    name="infos_pet"
                                >
                                    {confirmationOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="infos_auto"
                                label="Você pilota algum carro ou só curte a carona?"
                                id="infos_auto"
                                value={form.infos_auto}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="infos_horasvagas"
                                label="O que você gosta de fazer nas horas vagas?"
                                id="infos_horasvagas"
                                value={form.infos_horasvagas}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="infos_filhos-label">
                                    Tem filhos para brincar e fazer bagunça?
                                </InputLabel>
                                <Select
                                    labelId="infos_filhos-label"
                                    id="infos_filhos"
                                    label="Tem filhos para brincar e fazer bagunça?"
                                    value={form.infos_filhos}
                                    onChange={handleInputChange}
                                    name="infos_filhos"
                                >
                                    {confirmationOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="disponibilidade-viagem-label">
                                    Tá liberado para viajar e fazer campanhas por aí?
                                </InputLabel>
                                <Select
                                    labelId="disponibilidade-viagem-label"
                                    id="infos_viagens"
                                    label="Tá liberado para viajar e fazer campanhas por aí?"
                                    value={form.infos_viagens}
                                    onChange={handleInputChange}
                                    name="infos_viagens"
                                >
                                    {confirmationOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="info_cnpj-label">Você já tem CNPJ na área?</InputLabel>
                                <Select
                                    labelId="info_cnpj-label"
                                    id="info_cnpj"
                                    label="Você já tem CNPJ na área?"
                                    value={form.info_cnpj}
                                    onChange={handleInputChange}
                                    name="info_cnpj"
                                >
                                    {confirmationOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>

                <Box
                    sx={{
                        // position: 'fixed',
                        // bottom: 0,
                        mt: 10,
                        p: 2,
                        backgroundColor: 'var(--white-color)',
                        zIndex: 1,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <ButtonFL type="submit" fullWidth textKey="Salvar" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ButtonFL textKey="Voltar" fullWidth onClick={() => handleFechar(false)} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};
