import { Get } from '../data/Verbs';

export const apiGetClientes = async () => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/clientes`);
        return response;
    } catch (error) {
        throw error;
    }
};
