import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Chip,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { apiGetRankingPeriodos, apiPostRankingPeriodo, apiDeleteRankingPeriodo } from '../services/rankingPeriodos';

export const RankingPeriodos = ({ campanhaId }) => {
    const [periodos, setPeriodos] = useState([]);
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');

    const loadPeriodos = async () => {
        try {
            const data = await apiGetRankingPeriodos(campanhaId);
            setPeriodos(data);
        } catch (error) {
            console.error('Erro ao carregar períodos:', error);
        }
    };

    useEffect(() => {
        loadPeriodos();
    }, [campanhaId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                campanha: campanhaId,
                data_inicio: dataInicio,
                data_fim: dataFim,
                enable: true,
            };

            await apiPostRankingPeriodo(data);

            setDataInicio('');
            setDataFim('');
            loadPeriodos();
        } catch (error) {
            console.error('Erro ao salvar período:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await apiDeleteRankingPeriodo(id);
            loadPeriodos();
        } catch (error) {
            console.error('Erro ao deletar período:', error);
        }
    };

    const isCurrentPeriod = (periodo) => {
        const now = new Date();
        const start = new Date(periodo.data_inicio);
        const end = new Date(periodo.data_fim);
        return periodo.enable && now >= start && now <= end;
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
                Períodos do Ranking
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Data Início"
                            type="date"
                            value={dataInicio}
                            onChange={(e) => setDataInicio(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            required
                            fullWidth
                        />
                        <TextField
                            label="Data Fim"
                            type="date"
                            value={dataFim}
                            onChange={(e) => setDataFim(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            required
                            fullWidth
                        />
                    </Box>
                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        sx={{ bgcolor: 'primary.main', color: 'white', '&:hover': { bgcolor: 'primary.dark' } }}
                    >
                        Adicionar
                    </Button>
                </Box>
            </form>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Data Início</TableCell>
                            <TableCell>Data Fim</TableCell>
                            <TableCell align="right">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {periodos.map((periodo) => (
                            <TableRow key={periodo.id}>
                                <TableCell>
                                    {isCurrentPeriod(periodo) ? (
                                        <Chip label="Atual" color="success" size="small" />
                                    ) : periodo.enable ? (
                                        <Chip label="Ativo" color="primary" size="small" />
                                    ) : (
                                        <Chip label="Inativo" color="default" size="small" />
                                    )}
                                </TableCell>
                                <TableCell>{format(new Date(periodo.data_inicio), 'dd/MM/yyyy')}</TableCell>
                                <TableCell>{format(new Date(periodo.data_fim), 'dd/MM/yyyy')}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleDelete(periodo.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
